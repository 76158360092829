/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@font-face {
    font-family: TitilliumWeb;
    src: url(assets/fonts/TitilliumWeb-Regular.otf) format("opentype");
  }

  body{
    font: 100%/1.2 sans-serif;
    font-family:TitilliumWeb,arial,sans-serif;
    font-size: calc( 0.42vw + 8px);
    color:#015a6c;
    overflow-x: hidden;
  }

  .no-scroll .wrapper,.no-scroll .l-deashboard{
    height:100%;
  }
/*html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } --added by angular material*/
